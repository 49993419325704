require('./bootstrap');


// ...

// const BASE_URL = 'https://jsonplaceholder.typicode.com';

// const getTodoItems = async () => {
//   try {
//     console.log('get todo')
//     const response = await axios.get(`${BASE_URL}/todos?_limit=5`);

//     const todoItems = response.data;

//     console.log(`GET: Here's the list of todos`, todoItems);

//     return todoItems;
//   } catch (errors) {
//     console.error(errors);
//   }
// };

// let groupSelect = document.getElementById('group_select');

// groupSelect.remove;
// groupSelect.onchange = handleChange;


// // ...

// const createTodoElement = item => {
//     const optionElement = document.createElement('option');
  
//     optionElement.appendChild(document.createTextNode(item.title));
//     optionElement.setAttribute("value", item.id);
  
//     return optionElement;
//   };
  
//   const updateTodoList = todoItems => {
//     console.log('update todolist')
//     const todoList = document.getElementById('category_select');
  
//     if (Array.isArray(todoItems) && todoItems.length > 0) {
//       todoItems.map(todoItem => {
//         todoList.appendChild(createTodoElement(todoItem));
//       });
//     } else if (todoItems) {
//       todoList.appendChild(createTodoElement(todoItems));
//     }
//   };
  

// //   function handleChange(e) {
// //     console.log('handle change')
// //     updateTodoList(await getTodoItems());
// //   }
// // //   const main = async () => {
//   const handleChange = async (e) => {
//       console.log('main')
//     updateTodoList(await getTodoItems());
//   };
  
//   main();


// $(document).ready(function () {
    $(function() {
        console.log( "ready!" );

        $("#group_select").on("change", function() {
            console.log( "click" );
            var country_id = $(this).val();
            $.get( "/users.php", {
                id: $(this).val()
            }, function( resp ) {
                console.log( resp ); // server response
            });
            if(country_id != "") {
              $.ajax({
                url:"get-states.php",
                data:{c_id:country_id},
                type:'POST',
                success:function(response) {
                  var resp = $.trim(response);
                  $("#state").html(resp);
                }
              });
            } else {
              $("#category_select").html("<option value=''>------- Select --------</option>");
            }
          });
    // $.ajaxSetup({
    //   headers: {
    //       'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //   }
    // });
    
        $('.toast').toast('show');
        // var s = new summernote('#summernote');
        // $('#summernote').summernote();
        // var editor = new FroalaEditor('#wysiwyg');
        // var editor2 = new FroalaEditor('#wysiwyg_2');
    
    
        // var editor = new FroalaEditor('#wysiwyg', {
        //     // Set the image upload parameter.
        //     imageUploadParam: 'file',
        //     // Set the image upload URL.
        //     imageUploadURL: '/proxima/upload/wysiwyg',
        //     // Additional upload params.
        //     imageUploadParams: {id: 'my_editor', _token:  $('meta[name="csrf-token"]').attr('content')},
        //     // Set request type.
        //     imageUploadMethod: 'POST',
        //     // Set max image size to 5MB.
        //     imageMaxSize: 5 * 1024 * 1024,
        //     // Allow to upload PNG and JPG.
        //     imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        //     events: {
        //       'image.beforeUpload': function (images) {
        //         // Return false if you want to stop the image upload.
        //       },
        //       'image.uploaded': function (response) {
        //         // Image was uploaded to the server.
        //       },
        //       'image.inserted': function ($img, response) {
        //         // Image was inserted in the editor.
        //       },
        //       'image.replaced': function ($img, response) {
        //         // Image was replaced in the editor.
        //       },
        //       'image.error': function (error, response) {
        //         // Bad link.
        //         // if (error.code == 1) { ... }
        //         // // No link in upload response.
        //         // else if (error.code == 2) { ... }
        //         // // Error during image upload.
        //         // else if (error.code == 3) { ... }
        //         // // Parsing response failed.
        //         // else if (error.code == 4) { ... }
        //         // // Image too text-large.
        //         // else if (error.code == 5) { ... }
        //         // // Invalid image type.
        //         // else if (error.code == 6) { ... }
        //         // // Image can be uploaded only to same domain in IE 8 and IE 9.
        //         // else if (error.code == 7) { ... }
        //         // // Response contains the original server response to the request if available.
        //       }
        //     }
        //   })
    });